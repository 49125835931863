import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";
import { Text, Icon, useColorModeValue} from "@chakra-ui/react";
// Chakra imports
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
// Custom components
import Card from "components/card/Card.js";

export default function MiniCalendar(props) {
  const { selectRange, ...rest } = props;
  const [value, setValue] = useState(new Date());
  const [view, setView] = useState('month'); // Start with 'month' view
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  // Chakra Color Mode
  const borderColor = useColorModeValue("transparent", "whiteAlpha.100");
  const handleActiveStartDateChange = ({ activeStartDate, view }) => {
    setActiveStartDate(activeStartDate);
    setView(view);
  };

  const handleViewChange = (view) => {
    setView(view);
  };
  const handleCalendarChange = (value) => {
    setValue(value);
    // If a day is selected, reset view to 'month'
    if (view === 'month') {
      setView('month');
    } else if (view === 'year') {
      setView('month'); // After selecting a year, move to month selection
    } else if (view === 'decade') {
      setView('year'); // After selecting a decade, move to year selection
    }
    console.log('valiue', value)
  };
  return (
    <Card
      border='1px solid'
      borderColor={borderColor}
      align='center'
      direction='column'
      w='100%'
      maxW='max-content'
      p='20px 15px'
      h='max-content'
      {...rest}>
      <Calendar
        onChange={handleCalendarChange}
        value={value}
        selectRange={selectRange}
        view={view}
        onActiveStartDateChange={handleActiveStartDateChange}
        onViewChange={({ view }) => handleViewChange(view)}
        activeStartDate={activeStartDate}
        tileContent={<Text color='brand.500'></Text>}
        prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
        nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
      />
    </Card>
  );
}
