import { Box, Text, Flex, Button } from '@chakra-ui/react';
//icons
import { AiOutlineStop, AiOutlineCheck } from 'react-icons/ai';
export default function Default({question, userAnswered }) {
  const submitAnswer = (value) => {
    userAnswered(value);
  }
  return (
    <Box sx={{ textStyle: 'manropeHeading' }}>
      <Text>{question.Text}</Text>
      <Flex mt="18px" gap="32px" flexDirection="column">
        <Button
          leftIcon={<AiOutlineCheck style={{ fontSize: '24px' }} />}
          colorScheme="purple"
          bg="avenirSuitabilityColor.600"
          variant="solid"
          p="12px 18px"
          borderRadius="12px"
          h="46px"
          w='82px'
          _hover={{
            bg: 'avenirSuitabilityColor.600',
          }}
          onClick={() => submitAnswer('Yes')}
        >
          Yes
        </Button>
        <Button
          leftIcon={<AiOutlineStop style={{ fontSize: '24px' }} color='#4E13C3'/>}
          color="avenirSuitabilityColor.600"
          bg="avenirWhite"
          variant="solid"
          p="12px 18px"
          borderRadius="12px"
          h="46px"
          w='82px'
          borderColor="avenirSuitabilityColor.600"
          borderWidth="1px"
          borderStyle="solid"
          _hover={{
            bg: 'avenirWhite',
          }}
          onClick={() => submitAnswer('No')}
        >
          No
        </Button>
      </Flex>
    </Box>
  );
}
