import React, { useState } from 'react';
import { Box, Flex, Text, Textarea, Button } from '@chakra-ui/react';
//Icons
import { AiOutlineCheck } from 'react-icons/ai';

export default function Default({question, userAnswered }) {
  const [text, setText] = useState(`• \n• \n`);
  const submitAnswer = () => {
    userAnswered(text);
  }
  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text sx={{ textStyle: 'manropeHeading' }}>
          {question.Text}
        </Text>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          cursor="pointer"
        >
          Skip Question
        </Text>
      </Flex>
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="14px"
        color="avenirGrey.400"
      >
        {question.SubText}
      </Text>
      <Flex justifyContent="space-between" alignItems="end" mt="24px">
        <Textarea
          id="cause-input"
          value={text}
          placeholder="Type here..."
          size="lg"
          borderRadius="20px"
          h="80px"
          w="90%"
          resize="none"
          overflow="hidden"
          _placeholder={{
            fontSize: 'md',
            color: 'gray.400',
          }}
          pt="10px"
          pb="10px"
          onChange={(e) => setText(e.target.value)}
        />
        <Button
          colorScheme="purple"
          bg="avenirSuitabilityColor.600"
          variant="solid"
          p="8px"
          borderRadius="12px"
          h="36px"
          _hover={{
            bg: 'avenirSuitabilityColor.600',
          }}
          onClick={submitAnswer}
        >
          {<AiOutlineCheck style={{ fontSize: '24px' }} />}
        </Button>
      </Flex>
    </Box>
  );
}
