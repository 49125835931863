import React, { useState } from 'react';
import { Box, Flex, Text, Select, Button } from '@chakra-ui/react';
//Icons
import { AiOutlineCheck } from 'react-icons/ai';

export default function Default() {
  const [selectedOption, setSelectedOption] = useState('');
  const optionsArray = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
    // Add more options as needed
  ];

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    console.log('Selected Option:', selectedOption);
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text sx={{ textStyle: 'manropeHeading' }}>
          Why do you advise this product?{' '}
        </Text>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          cursor="pointer"
        >
          Skip Question
        </Text>
      </Flex>
      <Flex justifyContent="space-between" alignItems="end" mt="24px">
        <Select placeholder="Select option" onChange={handleChange} w='320px'>
          {optionsArray.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <Button
          colorScheme="purple"
          bg="avenirSuitabilityColor.600"
          variant="solid"
          p="12px"
          borderRadius="12px"
          h="36px"
          _hover={{
            bg: 'avenirSuitabilityColor.600',
          }}
        >
          {<AiOutlineCheck style={{ fontSize: '24px' }} />}
        </Button>
      </Flex>
    </Box>
  );
}
