import React, { useState } from 'react';
import { Box, Text, VStack, IconButton, Flex } from '@chakra-ui/react';
import { IoCloudUpload } from 'react-icons/io5';
import { AiOutlineCheck } from 'react-icons/ai';

const UploadComponent = ({ onFilesAdded }) => {
  const fileInputRef = React.useRef();

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFilesAddedEvt = (event) => {
    const files = event.target.files;
    if (files.length) {
      onFilesAdded(Array.from(files));
    }
  };

  return (
    <VStack
      spacing={3}
      p={5}
      backgroundColor="avenirGrey.100"
      borderRadius="16px"
      textAlign="center"
      cursor="pointer"
      role="group"
      _hover={{
        borderColor: 'blue.300',
      }}
      onClick={openFileDialog}
      mb='6px'
    >
      <input
        ref={fileInputRef}
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={onFilesAddedEvt}
        accept=".pdf,.doc,.docx,.xls,.xlsx,.png"
      />
      <IconButton
        variant="ghost"
        colorScheme="avenirSuitabilityColor"
        aria-label="Upload files"
        fontSize="41px"
        icon={<IoCloudUpload />}
        onClick={openFileDialog}
        _groupHover={{
          color: 'avenirSuitabilityColor.600',
        }}
      />
      <Text fontWeight="bold" color="black">
        Drop your files here, or{' '}
        <Text as="span" color="avenirSuitabilityColor.600">
          browse
        </Text>
      </Text>
      <Text sx={{ textStyle: 'manropeHeading' }} fontSize="sm" color="gray.500">
        PDF, Word, Excel and PNG files are allowed
      </Text>
    </VStack>
  );
};

export default function Default({ info }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const handleFilesAdded = (files) => {
    // Handle the file upload logic here
    let newActiveSteps = [...uploadedFiles, files[0].name];
    setUploadedFiles(newActiveSteps);
  };
  return (
    <Box p="16px 25px" bg="avenirWhite" borderRadius="8px" mt="25px">
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="18px"
        color="avenirSuitabilityColor.800"
      >
        {info.question}
      </Text>
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="14px"
        color="avenirGrey.400"
        mb="12px"
        fontWeight='500'
      >
        {info.subText}
      </Text>
      <UploadComponent onFilesAdded={handleFilesAdded} />
      {uploadedFiles.map((fileName, index) => (
        <Flex key="index" alignItems='center'>
          <AiOutlineCheck style={{ fontSize: '16px', color: '#4E13C3' }} />
          <Text   sx={{ textStyle: 'manropeHeading' }} color='avenirSuitabilityColor.600' ml='5px'>{fileName}</Text>
        </Flex>
      ))}
    </Box>
  );
}
