import React, { useState } from 'react';
import { Box, Flex, Text, Textarea } from '@chakra-ui/react';

export default function Default({ info }) {
  const [text, setText] = useState(`• \n• \n• \n• \n`);
  // const
  return (
    <Box bg="avenirWhite" p="16px 25px" mt="25px" borderRadius="8px">
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          color="avenirSuitabilityColor.800"
        >
          {info.question}
        </Text>
      </Flex>
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="14px"
        color="avenirGrey.400"
        fontWeight='500'
      >
        {info.subText}
      </Text>
      <Flex justifyContent="space-between" alignItems="end" mt="12px">
        <Textarea
          id="cause-input"
          value={text}
          placeholder="Type here..."
          size="lg"
          borderRadius="20px"
          h="130px"
          w="95%"
          resize="none"
          overflow="hidden"
          _placeholder={{
            fontSize: 'md',
            color: 'gray.400',
          }}
          pt="10px"
          pb="10px"
          onChange={(e) => setText(e.target.value)}
        />
      </Flex>
    </Box>
  );
}
