import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
//Icons
import { AvenirWhiteLogo } from 'components/icons/Icons';
// Componenets
import ValueMappingQuestions from 'components/value-mapping';

function SurveyQuestion() {
  return (
    <Box h="100vh" w="100vw">
      <Flex>
        <Box h="100vh" w="32vw" display={{ base: 'none', xl: 'block' }} borderRadius="0 1rem 1rem 0" bg="avenirBlack">
          <Box h="46px" w="210px" m="7vh 0 0 5vw">
            <AvenirWhiteLogo h="full" w="full" />
            <Text
              color="avenirWhite"
              mt="15vh"
              fontWeight="100"
              sx={{ textStyle: 'popinextralarge' }}
            >
              {' '}
              Investing
            </Text>
            <Text
              color="avenirWhite"
              sx={{ textStyle: 'popinextralarge' }}
              w="max-content"
              bg="avenirPurple.700"
              p="8px 18px 8px 18px"
              mt="10px"
              borderRadius="24px"
            >
              with Purpose.
            </Text>
            <Text
              color="avenirWhite"
              mt="25px"
              w="max-content"
              sx={{ textStyle: 'popinextralarge' }}
            >
              Shape your future
            </Text>
            <Text
              color="avenirWhite"
              w="max-content"
              fontWeight="100"
              sx={{ textStyle: 'popinextralarge' }}
            >
              with values in mind.
            </Text>
          </Box>
        </Box>
        <Box>
          <ValueMappingQuestions/>
        </Box>
      </Flex>
    </Box>
  );
}

export default SurveyQuestion;
