import React, { useEffect, useState } from 'react';
import { Box, Checkbox, CheckboxGroup, Stack, Text } from '@chakra-ui/react';


function McqTypeQuestions({ valueProps, valueQuestion }) {
  const { vMapQuestionsContext, updatevMapQuestionsContext } = valueProps;
  const [checkedItems, setCheckedItems] = useState([]);
  const VMapQuestion =  valueQuestion
  const vMapAnswers = {
    answer: vMapQuestionsContext[6].answers[0] === 'Yes' ? VMapQuestion.answeresYes : VMapQuestion.answeresNo
  };
  const handleCheckChange = (value) => {
    setCheckedItems([value]);
    updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, [value]);
  };
  useEffect(() =>{
    setCheckedItems(valueProps.vMapQuestionsContext[valueQuestion.id].answers)
  }, [valueProps.vMapQuestionsContext, valueQuestion.id])
  return (
    <Box p={5} width={{ base: "95%", xl: "60%" }} mt="15px">
      <Text mb='46px' sx={{
          textStyle: { base: 'popinsmallHeading', xl: 'popinlarge' },
        }}>
        {VMapQuestion.question}
      </Text>
      <CheckboxGroup value={checkedItems} onChange={handleCheckChange} mt="25px">
        <Stack direction="column" rowGap="5px">
          {vMapAnswers.answer.map((answer, index) => (
            <Checkbox
              key={answer.id}
              value={answer.value}
              border="1px solid"
              borderColor="avenirPurple.600"
              width={{ base: "95%", xl: "85%" }}
              p="15px 0 15px 28px"
              borderRadius="18px"
              onChange={() => handleCheckChange(answer.value)}
              sx={{textStyle: 'popinsmall'}}
              _checked={{
                bg: 'avenirPurple.50', 
                borderColor: 'purple.600',
                iconColor: 'purple.600',
              }}
              _focus={{
                boxShadow: 'none',
              }}
              colorScheme="purple"
            >
              {answer.value}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </Box>
  );
}

export default McqTypeQuestions;
