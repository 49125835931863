import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Grid,
  Box,
  Text,
  VStack,
  theme,
  useMultiStyleConfig,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import {
  MdCheckCircle,
  MdOutlineWbSunny,
  MdOutlineLocalFlorist,
  MdOutlineWaterDrop,
} from 'react-icons/md';
import { HiOutlineGlobeAsiaAustralia } from 'react-icons/hi2';
import { TbSunFilled } from "react-icons/tb";
import { GiWaterRecycling } from "react-icons/gi";
import { IoPawOutline } from "react-icons/io5";


const topicsIcons = [
  { icon: HiOutlineGlobeAsiaAustralia },
  { icon: MdCheckCircle },
  { icon: TbSunFilled },
  { icon: GiWaterRecycling },
  { icon: MdOutlineLocalFlorist },
  { icon: MdOutlineWaterDrop },
  { icon: IoPawOutline },
  { icon: MdOutlineWbSunny },
  { icon: MdCheckCircle },
];

function TopicsSelection({ valueProps, valueQuestion }) {
  const { vMapQuestionsContext, updatevMapQuestionsContext } = valueProps;
  const { getCheckboxProps } = useCheckboxGroup();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedColor, setselectedColor] =useState("#E7D6FF")
  const val = [];
  const [nOfSelect, setNOfSelect] = useState(valueQuestion.id < 11 ? 3 : 2);

  const handleTopicSelect = (topicId) => {
    setSelectedTopics((prevSelectedTopics) => {
      if (prevSelectedTopics.includes(topicId)) {
        return prevSelectedTopics.filter((id) => id !== topicId);
      } else {
        return [...prevSelectedTopics, topicId].slice(-nOfSelect);
      }
    });
  };
  useEffect(() => {
    valueQuestion.answers.forEach((ans) => {
      if (selectedTopics.includes(ans.id)) {
        val.push(ans.value);
      }
    });
    updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, val);
    setNOfSelect(valueQuestion.id < 11 ? 3 : 2);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopics, valueQuestion.id]);
  useEffect(() => {
    let idVal = [];
    valueQuestion.answers.forEach((idvals) => {
      if (
        vMapQuestionsContext[valueQuestion.id].answers.includes(idvals.value)
      ) {
        idVal.push(idvals.id);
      }
    });
    setSelectedTopics(idVal);
    if (valueQuestion.question === 'Environmental') {
      setselectedColor('#E7D6FF')
    } else if (valueQuestion.question === 'Social') {
      setselectedColor('#FFF0D4')
    } else {
      setselectedColor('#FFE3D4')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueQuestion.question]);
  const styles = useMultiStyleConfig('CustomCheckbox', {});

  return (
    <ChakraProvider theme={theme}>
      <VStack
        spacing={4}
        align="stretch"
        p={5}
        width={{ base: '95%', xl: '60%' }}
      >
        <Text
          fontWeight="bold"
          mb={2}
          sx={{
            textStyle: { base: 'popinsmallHeading', xl: 'popinlarge' },
          }}
        >
          Select up to {nOfSelect} topics that you would like to prioritize
        </Text>
        <Text color="gray.600" mb={6}>
          You will have the possibility to change them later
        </Text>
        <Text fontSize="xl" fontWeight="semibold" mb={4}>
          {valueQuestion.question}
        </Text>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={{ base: '2', xl: '4' }}
          maxHeight="60vh"
          overflow="scroll"
          width={{ base: '100%', xl: '92%' }}
        >
          {valueQuestion.answers.map((topic, index) => (
            <Box
              as="button"
              key={topic.id}
              {...getCheckboxProps({ value: topic.id })}
              onClick={() => handleTopicSelect(topic.id)}
              borderWidth="1px"
              borderRadius="24px"
              p={4}
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              backgroundColor={selectedTopics.includes(topic.id) ? selectedColor : 'white'}
              borderColor={
                selectedTopics.includes(topic.id) ? selectedColor : 'gray.300'
              }
              _hover={{
                bg: selectedColor,
                borderColor: selectedColor,
              }}
              width={{ base: '40vw', xl: '17vw' }}
              height={{ base: '12vh', xl: '15vh' }}
              sx={styles.container}
            >
              <Icon
                as={topicsIcons[index].icon}
                w={6}
                h={6}
                color="#909090"
              />
              <Text sx={{
          textStyle: { base: 'small', xl: 'medium' },
        }}>{topic.value}</Text>
            </Box>
          ))}
        </Grid>
      </VStack>
    </ChakraProvider>
  );
}

export default TopicsSelection;
