/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import DecoupledEditor from '../../../ckeditor5-custom/build/ckeditor';

const CustomCKEditor = ({
  data,
  onChange,
  isReportFinished,
  isGeneratePdfOrDoc,
}) => {
  const editorRef = useRef();
  const toolbarRef = useRef();
  const editorInstanceRef = useRef(null);
  useEffect(() => {
    if (editorRef.current && !editorInstanceRef.current) {
      // Initialize the editor if it hasn't been initialized yet
      DecoupledEditor.create(editorRef.current, {
        documentOutline: {
          container: document.querySelector('.document-outline-container'),
        },
      })
        .then((editor) => {
          editorInstanceRef.current = editor; // Store the editor instance
          toolbarRef.current.appendChild(editor.ui.view.toolbar.element);
          editor.model.document.on('change:data', () => {
            onChange(editor.getData());
          });
          changeInData();
        })
        .catch((error) => {
          console.error('There was a problem initializing the editor:', error);
        });
    }

    // Cleanup function
    return () => {
      if (editorInstanceRef.current) {
        editorInstanceRef.current
          .destroy()
          .then(() => {
            editorInstanceRef.current = null;
          })
          .catch((error) => {
            console.error('Error during editor cleanup:', error);
          });
      }
    };
  }, []);
  useEffect(() => {
    changeInData();
  }, [data]);
  useEffect(() => {
    if (isGeneratePdfOrDoc === 'DOC') {
      if (editorInstanceRef.current && data) {
        console.log('dfjksjdkljskdjlksj')
        const config = {
          stylesheets: ['EDITOR_STYLES'],
          fileName: 'avenir_suitability_report.docx',
          converterUrl: 'https://docx-converter.cke-cs.com/v1/convert',
          converterOptions: {
            format: 'A4',
            margin_top: '15mm',
            margin_bottom: '15mm',
            margin_right: '15mm',
            margin_left: '15mm',
          }
        }
        editorInstanceRef.current.execute('exportWord', config);
      }
    }
    if (isGeneratePdfOrDoc === 'PDF') {
      if (editorInstanceRef.current && data) {
        console.log('dfjksjdkljskdjlksj')
        const config = {
          stylesheets: ['EDITOR_STYLES'],
          fileName: 'avenir_suitability_report.pdf',
          converterUrl: 'https://pdf-converter.cke-cs.com/v1/convert',
          converterOptions: {
            format: 'A4',
            margin_top: '15mm',
            margin_bottom: '15mm',
            margin_right: '15mm',
            margin_left: '15mm',
          }
        }
        editorInstanceRef.current.execute('exportPdf', config);
      }
    }
  }, [isGeneratePdfOrDoc]);
  const changeInData = () => {
    if (editorInstanceRef.current && data) {
      const htmlData = data;
      const currentEditorData = editorInstanceRef.current.getData();
      // Check if the editor data needs to be updated (to prevent overriding user input)
      if (currentEditorData !== htmlData) {
        editorInstanceRef.current.setData(htmlData);
      }
    }
  };
  return (
    <Flex
      h="95%"
      alignItems="center"
      bg="white"
      flexDirection="column"
      backgroundColor="avenirGrey.200"
      border="1px"
      borderColor="avenirGrey.400"
    >
      <Flex ref={toolbarRef} width="100%" backgroundColor="avenirWhite" />
      <Flex
        p={4}
        width="100%"
        overflowY="auto"
        alignItems="center"
        backgroundColor="avenirGrey.200"
        mt="10px"
      >
        <VStack
          spacing={4}
          alignItems="flex-start"
          borderRightWidth="1px"
          borderColor="gray.200"
          bg="white"
          width="17%"
          height="100%"
          overflowY="auto"
          backgroundColor="avenirGrey.200"
        >
          {/* Document outline */}
          <Text fontWeight="bold" fontSize="xl">
            Document Outline
          </Text>
          <Box className="document-outline-container" w="100%">
            {/* <!-- The document outline will be dynamically filled here --> */}
          </Box>
        </VStack>
        <Box
          ref={editorRef}
          h={isReportFinished ? '82vh' : '53vh'}
          width="50vw"
          borderWidth="1px"
          borderColor="gray.200"
          backgroundColor="avenirWhite"
          p="10px 30px !important"
        />
      </Flex>
    </Flex>
  );
};

export default CustomCKEditor;
