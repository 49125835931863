import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Checkbox,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { AiOutlineCheck } from 'react-icons/ai';

export default function Default( {question, userAnswered }) {
  // Initialize state to track the selected checkbox
  const [selectedOption, setSelectedOption] = useState('');
  const [isOtherSelected, setisOtherSelected] = useState(false);
  const [questionsAnswer, setquestionsAnswer ] = useState('')
  const mcqQuestion = question
  const options = mcqQuestion.Answers.map((value, index) => ({
    id: index + 1, 
    value: value,  
    label: value 
  }));
   // Append the 'Other' option
   options.push({
    id: mcqQuestion.Answers.length + 1, 
    value: 'other',
    label: 'Other'
  });
  const [text, setText] = useState(`• \n• \n`);
  const handleChange = (value) => {
    setSelectedOption(selectedOption === value ? '' : value);
    if (value === 'other') {
      setisOtherSelected(true);
      setquestionsAnswer(text)
    } else {
      setisOtherSelected(false);
      setquestionsAnswer(value)
    }
  };
  const handleTextChange = (textValue) => {
    setText(textValue)
    setquestionsAnswer(textValue)
  }
  const submitAnswer = () => {
    userAnswered(questionsAnswer);
  }

  return (
    <Box h="100%" overflow="scroll">
      <Flex justifyContent="space-between" alignItems="center">
        <Text sx={{ textStyle: 'manropeHeading' }} >
         {mcqQuestion.Text}
        </Text>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          cursor="pointer"
        >
          Skip Question
        </Text>
      </Flex>
      <Flex justifyContent="space-between" alignItems="end" mt="10px">
        <Box>
          <Stack>
            {options.map((option) => (
              <Checkbox
                key={option.id}
                value={option.value}
                isChecked={selectedOption === option.value}
                onChange={() => handleChange(option.value)}
                colorScheme="avenirSuitabilityColor"
                sx={{
                  textStyle: 'manropeHeading',
                  '.chakra-checkbox__control': { borderRadius: '4px' },
                }}
              >
                <Text ml="14px" color="avenirGrey.600">{option.label}</Text>
              </Checkbox>
            ))}
          </Stack>
          {isOtherSelected ? (
            <>
              <Textarea
                id="cause-input"
                value={text}
                placeholder="Type here..."
                size="lg"
                borderRadius="20px"
                h="80px"
                w="720px"
                resize="none"
                overflow="hidden"
                _placeholder={{
                  fontSize: 'md',
                  color: 'gray.400',
                }}
                pt="10px"
                pb="10px"
                mt="20px"
                onChange={(e) => handleTextChange(e.target.value)}
              />
            </>
          ) : null}
        </Box>
        <Button
          colorScheme="purple"
          bg="avenirSuitabilityColor.600"
          variant="solid"
          p="8px"
          borderRadius="12px"
          h="36px"
          _hover={{
            bg: 'avenirSuitabilityColor.600',
          }}
          onClick={submitAnswer}
        >
          {<AiOutlineCheck style={{ fontSize: '24px' }} />}
        </Button>
      </Flex>
    </Box>
  );
}
