import { Box, Button, Text, Flex } from '@chakra-ui/react';
import UploadUserInformation from './UploadUserInformation';
import ClinetsData from './ClinetsData';
export default function Default({sectionChange}) {
  const clientInformationSections = [
    {
      id: 1,
      question: 'Upload Clients’ current pension documents',
      subText:
        'Everything related to the pensions itself (statement, product feature, mainly).',
      type: 'Upload',
    },
    {
      id: 2,
      question: 'Write or paste clients’ personal details',
      subText:
        'Any format is fine, our AI will understand it. Paste everything you know about your client (name, income, liability...).',
      type: 'Text',
    },
    {
      id: 3,
      question: 'Upload clients’ information documents',
      subText:
        'Risk assessment, goal assessment, any document you have on your client!',
      type: 'Upload',
    },
    {
      id: 4,
      question: 'Write or paste clients’ objectives',
      subText:
        'Any format is fine, our AI will understand it. Bullet point is the most optimised to structure objectives.',
      type: 'Text',
    },
    {
      id: 5,
      question: 'Anything else about your client we should consider?',
      subText:
        'Add any additional details. You can paste a transcript of your conversations with them directly. Don’t bother formatting. ',
      type: 'Text',
    },
  ];
  const createReportClicked = () => {
    sectionChange(1)
  }
  return (
    <Box w="80vw" h="100vh" overflow="scroll" bg="avenirGrey.100">
      <Box m="31px">
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          color="avenirGrey.400"
          fontWeight="600"
          fontSize="12px"
        >
          Dashboard / Suitability Report / Create
        </Text>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="24px"
          color="avenirSuitabilityColor.800"
          mb="25px"
        >
          Clients’ Information
        </Text>
        {clientInformationSections.map((info, index) => {
          if (info.type === 'Upload') {
            return <UploadUserInformation key={index} info={info} />;
          } else if (info.type === 'Text') {
            return <ClinetsData key={index} info={info} />;
          } else {
            return null;
          }
        })}
        <Flex mt="25px" justifyContent="end">
          <Button
            backgroundColor="avenirSuitabilityColor.600"
            color="white"
            w="206px"
            h="56px"
            sx={{ textStyle: 'manropeHeading' }}
            fontSize="18px"
            _hover={{
              cursor: 'pointer',
              backgroundColor: 'avenirSuitabilityColor.600',
            }}
            onClick={() => createReportClicked()} 
          >
            Create Report
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
