import { Box } from '@chakra-ui/react';
import SuitabilityReportView from '../../views/suitabilityReportViews/report'

export function SuitabilityReport() {
  return (
    <Box>
      <SuitabilityReportView></SuitabilityReportView>
    </Box>
  );
}
