import { combineReducers } from 'redux';
import financialDataReducer from './myReducer';
import compositionReducer from './compositionReducer';
import personalisedPortReducer from './personalisedPortReducer';
import currentStepReducer from './currentStepReducer';
import SuitabilityProgressReducer from './SuitabilityProgressReducer'

const rootReducer = combineReducers({
  my: financialDataReducer,
  cr: compositionReducer,
  ppd: personalisedPortReducer,
  cs: currentStepReducer,
  spr: SuitabilityProgressReducer
  // Add other reducers here when you have them
});

export default rootReducer;


