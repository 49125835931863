/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Text, Flex, Progress } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
//Constants
import {
  API_SUBSECTION_QUESTION,
  API_SUITABILITY_SUBESTION_USER_ANSWER,
} from 'static/constants';
//components
import YesOrNo from './YesOrNo';
import TypeAnswer from './TypeAnswer';
import DropDownAnswer from './DropDownAnswer';
import SelectAnswer from './SelectAnswer';
import CalendarAnswer from './CalendarAnswer';

export default function Default({ updateAfterUserAnswer, progressValue, isAiGenerating }) {
  const [questionType, setquestionType] = useState('YesOrNo');
  const [question, setQuestion] = useState({});
  // const [userAnswer, setUserAnswer] = useState('')
  const userAnswered = (answer) => {
    // setUserAnswer(answer)
    postUserAnswer(answer);
  };
  const postUserAnswer = async (answer) => {
    const apiSubsectionQuestionUrl = API_SUITABILITY_SUBESTION_USER_ANSWER;
    let requestBody = {
      userAnswerText: answer,
    };
    try {
      // Append the reportName as a query parameter
      const response = await axios.post(apiSubsectionQuestionUrl, requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      updateAfterUserAnswer();
      getSubsectionQuestion();
    } catch (error) {
      console.error('Error making the GET progress request:', error);
      throw error;
    }
  };
  const getSubsectionQuestion = async () => {
    const apiSubsectionQuestionUrl = API_SUBSECTION_QUESTION;
    try {
      // Append the reportName as a query parameter
      const response = await axios.get(apiSubsectionQuestionUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setQuestion(response.data);
      setquestionType(response.data.Type);
    } catch (error) {
      console.error('Error making the GET progress request:', error);
      throw error;
    }
  };
  useEffect(() => {
    getSubsectionQuestion();
  }, []);
  return (
    <Box
      w="95%"
      h="100%"
      borderColor="avenirGrey.200"
      borderWidth="1px"
      borderStyle="solid"
      bg="avenirWhite"
      borderRadius="8px"
    >
      <Progress value={progressValue} w="95%" h="6px" />
      <Box
        w="100%"
        p="16px"
        borderRadius="0 0 8px 8px"
        h="100%"
        overflow="scroll"
      >
        {isAiGenerating ? (
          <Flex gap="9px" alignItems="center">
            <BsThreeDots style={{ fontSize: '24px' }} color="#CEA2FC" />
            <Text
              sx={{ textStyle: 'manropeHeading' }}
              color="avenirSuitabilityColor.300"
            >
              AI is writing
            </Text>
          </Flex>
        ) : (
          <Box h="100%" overflow="scroll">
            {questionType === 'YesOrNo' ? <YesOrNo question={question} userAnswered={userAnswered}/> : null}
            {questionType === 'Type' ? <TypeAnswer question={question} userAnswered={userAnswered}/> : null}
            {questionType === 'Dropdown' ? <DropDownAnswer /> : null}
            {questionType === 'MCQ' ? (
              <SelectAnswer question={question} userAnswered={userAnswered} />
            ) : null}
            {questionType === 'calendar' ? <CalendarAnswer /> : null}
          </Box>
        )}
      </Box>
    </Box>
  );
}
