const PROD_SERVER = "https://suitabilityreportbe-stefan-demo-dh2w7rixfa-nw.a.run.app/"
const LOCAL_SERVER = "" 
// In dev, we use axios and a proxy to manage the connexion to the url with no cors errors. 
// This is manatained in package.json. Could be local or remote, don't care. 

// TODO : Change this is you want to move to dev/prod environment 
const ENVIRONMENT = "prod"

export const SERVER_URL = ENVIRONMENT === "prod" ? PROD_SERVER : LOCAL_SERVER

// URL for backend calls 
export const API_PORTFOLIO_URL = SERVER_URL + "/api/portfolio/"
export const API_INITIATE_SUITABILITY_REPORT = SERVER_URL + "api/report/initiate/"
export const API_SECTIONS_URL_BASE = SERVER_URL + 'api/report/sections/'
export const API_SUITABILITY_PROGRESS = SERVER_URL + 'api/progress/'
export const API_SUBSECTION_QUESTION = SERVER_URL + 'api/question/'
export const API_SUITABILITY_SERVER_ANSWER = SERVER_URL + 'api/serverAnswer/'
export const API_SUITABILITY_SUBESTION_USER_ANSWER = SERVER_URL + 'api/userAnswer/'