import React, { useState } from 'react';
import { Box, Flex, IconButton, HStack, Progress, Text } from '@chakra-ui/react';
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import valueMappingQuestions from './components/valueMappingQuestions';

import McqTypeQuestions from 'components/value-mapping/components/McqTypeQuestions';
import TextTypeQuestions from 'components/value-mapping/components/TextTypeQuestions';
import SelectTypeQuestions from 'components/value-mapping/components/SelectTypeQuestions';
import RatingTypeQuestions from 'components/value-mapping/components/RatingTypeQuestions';
import McqFollowUpQuestions from 'components/value-mapping/components/McqFollowUpQuestions';
import { AvenirLogo, AvenirWhiteLogo } from 'components/icons/Icons'

// value mapping context
const initialState = {
  1: { id: 1, question: 'Question 1', answers: [] },
  2: { id: 2, question: 'Question 2', answers: [] },
  3: { id: 3, question: 'Question 3', answers: [] },
  4: { id: 4, question: 'Question 4', answers: [] },
  5: { id: 5, question: 'Question 5', answers: [] },
  6: { id: 6, question: 'Question 6', answers: [] },
  7: { id: 7, question: 'Question 7', answers: [] },
  8: { id: 8, question: 'Question 8', answers: [] },
  9: { id: 9, question: 'Question 9', answers: [] },
  10: { id: 10, question: 'Question 10', answers: [] },
  11: { id: 11, question: 'Question 11', answers: [] },
  12: { id: 12, question: 'Question 12', answers: [] },
};
export default function Default() {
  const [vMapQuestionsContext, setvMapQuestionsContext] =
    useState(initialState);
  const questions = valueMappingQuestions.valueMappingQuestions;
  const questionLength = questions.length;
  const [questionNumber, setQuestionNumber] = useState(1);
  const increaseNumber = () => {
    if(vMapQuestionsContext[questionNumber].answers.length > 0){
    setQuestionNumber((prevNumber) =>
      prevNumber < questionLength ? prevNumber + 1 : prevNumber,
    );
    }
  };
  const decreaseNumber = () => {
    setQuestionNumber((prevNumber) =>
      prevNumber > 1 ? prevNumber - 1 : prevNumber,
    );
  };
  const progressValue = (questionNumber / questionLength) * 100;
  const updatevMapQuestionsContext = (id, question, answers) => {
    setvMapQuestionsContext((prevQuestions) => ({
      ...prevQuestions,
      [id]: { ...prevQuestions[id], question, answers },
    }));
  };
  return (
    <Box w={{ base: '100vw', xl: '68vw' }} >
      <Progress value={progressValue} w="100%" ml="-20px" zIndex="-1" />
      <Flex
        sx={{ textStyle: 'regular' }}
        fontWeight="bold"
        width={{ base: "95%", xl: "90%" }}
        justifyContent={{ base: "space-between", xl: "end" }}
        alignItems="baseline"
        mt={{ base: "3vh", xl: "10vh" }}
      >
        <AvenirLogo ml="7.5vw" h="36px" w="128px" display={{ base: 'block', xl: 'none' }} />
        {questionNumber}/{questionLength}
      </Flex>
      <Flex justifyContent="center" width="full">
        {questions[questionNumber - 1].type === 'McqTypeQuestions' ? (
          <McqTypeQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
        {questions[questionNumber - 1].type === 'TextTypeQuestions' ? (
          <TextTypeQuestions />
        ) : null}
        {questions[questionNumber - 1].type === 'SelectTypeQuestions' ? (
          <SelectTypeQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
        {questions[questionNumber - 1].type === 'RatingTypeQuestions' ? (
          <RatingTypeQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
        {questions[questionNumber - 1].type === 'McqTypeQuestionsFollowUp' ? (
          <McqFollowUpQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
      </Flex>
      <Flex
        position="fixed"
        width={{base: "100%", xl:"10px"}}
        left={{ base: '0', xl: 'auto' }} 
        right={{ xl: '15px', base: 'auto' }}
        justifyContent="space-between"
        bottom="0"
        p={{ xl: '24', base: '12px' }}
      >
        <HStack>
          <IconButton
            aria-label="Up"
            icon={<ArrowUpIcon />}
            variant="outline"
            colorScheme="purple"
            isRound={true}
            onClick={decreaseNumber}
            disabled={questionNumber === 1}
          />
          <IconButton
            aria-label="Down"
            icon={<ArrowDownIcon />}
            colorScheme="purple"
            variant="solid"
            isRound={true}
            backgroundColor="avenirPurple.700"
            onClick={increaseNumber}
            disabled={questionNumber === questionLength}
          />
        </HStack>
        <Box display={{ base: 'flex', xl: 'none' }} w="182px" h="42px" borderRadius="24px" justifyContent="space-around" alignItems="center" bg="avenirBlack">
          <Text sx={{textStyle: 'small'}} color="avenirWhite"> Powered by</Text>
          <AvenirWhiteLogo h="26px" w="78px" />
        </Box>
      </Flex>
    </Box>
  );
}
