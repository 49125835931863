import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setSuitabilityProgress } from 'reducers/actions';
//Icons

//Component
import QuestionArea from 'views/suitabilityReportViews/components/QuestionArea';
import SuitabilityCkEdiotr from 'views/suitabilityReportViews/components/SuitabilityCkEdiotr';
import SideNavbar from 'views/suitabilityReportViews/components/SideNavBar';
import ClientInformation from 'views/suitabilityReportViews/components/ClientInformation';
import {
  API_INITIATE_SUITABILITY_REPORT,
  API_SECTIONS_URL_BASE,
  API_SUITABILITY_PROGRESS,
  API_SUITABILITY_SERVER_ANSWER,
} from 'static/constants';
axios.defaults.withCredentials = true;
const Sidebar = () => {
  const dispatch = useDispatch();
  const [editorData, setEditorData] = useState('');
  const [sectionDetails, setsectionDetails] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const financialData = useSelector((state) => state);
  const SuitabilityProgress = financialData.spr;
  const [isAiGenerating, setisAiGenerating] = useState(false);
  const subSectionNumber = SuitabilityProgress.SectionNumber;
  const isReportFinished = SuitabilityProgress.ReportFinished;
  const [makeCkEditorFull, setMakeCkEditorFull] = useState(false);
  const [isGeneratePdfOrDoc, setisGeneratePdfOrDoc] = useState('');
  const handleEditorDataChange = (newData) => {
    setEditorData(newData);
  };
  const sectionChange = (value) => {
    setCurrentSection(value);
    if (value === 4) {
      setMakeCkEditorFull(true);
      setTimeout(() => {
        setMakeCkEditorFull(false);
      }, 500);
    }
  };
  const generatePdfOrDoc = (value) => {
    setisGeneratePdfOrDoc(value);
  };
  const progressValue =
    (SuitabilityProgress.CurrentQuestionNumber /
      SuitabilityProgress.QuestionsInsideSubsection) *
    100;
  //user answered the question
  const updateAfterUserAnswer = () => {
    getSuitabilityProgress();
    getServerAnswer();
  };
  //Initiate Report Api OnMount
  const InitiateReport = async () => {
    const apiInitiateUrl = API_INITIATE_SUITABILITY_REPORT;
    let requestBody = {
      templateNameChoice: 'Demo',
    };
    try {
      const response = await axios.post(apiInitiateUrl, requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getSectionInReport();
      return response.data;
    } catch (error) {
      console.error('Error making the Initiate Report request:', error);
      throw error;
    }
  };
  //Get Sections Api
  const getSectionInReport = async () => {
    const apiSectionsBaseUrl = API_SECTIONS_URL_BASE;
    const reportName = 'Demo';
    try {
      // Append the reportName as a query parameter
      const url = `${apiSectionsBaseUrl}?templateNameChoice=${reportName}`;
      const response = await axios.get(url);
      setsectionDetails(response.data);
      getSuitabilityProgress();
    } catch (error) {
      console.error('Error making the GET Sections request:', error);
      throw error;
    }
  };
  //Get Progress Api
  const getSuitabilityProgress = async () => {
    const apiSectionsBaseUrl = API_SUITABILITY_PROGRESS;
    try {
      // Append the reportName as a query parameter
      const response = await axios.get(apiSectionsBaseUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      dispatch(setSuitabilityProgress(response.data));
      getServerAnswer();
    } catch (error) {
      console.error('Error making the GET progress request:', error);
      throw error;
    }
  };
  //Get server AnswerApi
  const getServerAnswer = async () => {
    const apiServerAnsweUrl = API_SUITABILITY_SERVER_ANSWER;
    try {
      // Append the reportName as a query parameter
      const response = await axios.get(apiServerAnsweUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.data.ServerAnswerText.length) {
        setisAiGenerating(true);
        setTimeout(() => {
          setisAiGenerating(false);
          const correctedMarkdownContent =
            response.data.ServerAnswerText.replace(/(#+)([A-Za-z])/g, '$1 $2');
          setEditorData(editorData + correctedMarkdownContent);
        }, 800);
      } else {
        const correctedMarkdownContent = response.data.ServerAnswerText.replace(
          /(#+)([A-Za-z])/g,
          '$1 $2',
        );
        setEditorData(editorData + correctedMarkdownContent);
      }
    } catch (error) {
      console.error('Error making the GET progress request:', error);
      throw error;
    }
  };
  useEffect(() => {
    // updateActiveStep(0, SuitabilityProgress.SectionNumber-1)
  }, [SuitabilityProgress.SectionNumber]);
  useEffect(() => {
    InitiateReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex>
      <Box
        className="suitability_side_bar"
        p="28px"
        w="22vw"
        h="100vh"
        borderColor="avenirGrey.200"
        borderWidth="1px"
        borderStyle="solid"
      >
        <SideNavbar
          sectionDetails={sectionDetails}
          sectionChange={sectionChange}
          currentSection={currentSection}
          subSectionNumber={subSectionNumber}
          isReportFinished={isReportFinished}
          generatePdfOrDoc={generatePdfOrDoc}
        ></SideNavbar>
      </Box>
      {currentSection === 0 ? (
        <ClientInformation sectionChange={sectionChange}></ClientInformation>
      ) : (
        <Box w="78vw" bg="avenirGrey.100">
          {!makeCkEditorFull ? (
            <Box
              className="report-editor"
              h={isReportFinished ? '95vh' : '68vh'}
              w="95%"
              ml="40px"
              mt="20px"
            >
              <SuitabilityCkEdiotr
                data={editorData}
                onChange={handleEditorDataChange}
                isReportFinished={isReportFinished}
                isGeneratePdfOrDoc={isGeneratePdfOrDoc}
              />
            </Box>
          ) : null}
          {!isReportFinished ? (
            <Flex
              className="question-area"
              w="100%"
              h="28vh"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <QuestionArea
                updateAfterUserAnswer={updateAfterUserAnswer}
                progressValue={progressValue}
                isAiGenerating={isAiGenerating}
              ></QuestionArea>
            </Flex>
          ) : null}
        </Box>
      )}
    </Flex>
  );
};

export default Sidebar;
