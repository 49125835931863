import {  Box, Text, Textarea, FormControl, FormLabel } from '@chakra-ui/react';

function TextTypeQuestions() {
  return (
      <Box w="55%">
        <FormControl>
          <FormLabel fontSize="36px" fontWeight="bold" mb="5">
            Can you describe which causes are you focused on right now?
          </FormLabel>
          <Text sx={{textStyle: 'regular'}} mb="4">
            It's optional, but it would really help us to customize your portfolio.
          </Text>
          <Textarea
            id="cause-input"
            placeholder="e.g I'm currently involved in..."
            size="lg"
            borderRadius="20px"
            height="210px"
            resize="none"
            overflow="hidden" 
            _placeholder={{
              fontSize: 'md',
              color: 'gray.400',
            }}
            pt="10px" 
            pb="10px"
          />
        </FormControl>
      </Box>
  );
}

export default TextTypeQuestions;
