import React ,{useState}from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
//Icons
import { AiOutlineCheck } from 'react-icons/ai';
import { FaCalendarAlt } from 'react-icons/fa';
//components
import AvenirMiniCalendar from 'components/calendar/AvenirMiniCalendar';
export default function Default() {
    const [calenderOpen,setCalenderOpen] = useState(false)
    const onCLickChange = () => { 
        setCalenderOpen(true)
    }
  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text sx={{ textStyle: 'manropeHeading' }}>
          When did you Started Using this product?{' '}
        </Text>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          cursor="pointer"
        >
          Skip Question
        </Text>
      </Flex>
      <Flex justifyContent="space-between" alignItems="end" mt="24px">
        <Box>
          <Button
            leftIcon={<FaCalendarAlt style={{ fontSize: '24px' }} />}
            color="avenirSuitabilityColor.600" // Ensure this color is defined in your theme
            bg="avenirWhite" // Ensure this color is defined in your theme
            borderColor="avenirSuitabilityColor.600" // Removed the extra quotes
            border="1px"
            p="12px"
            variant="solid"
            borderRadius="12px"
            onClick={onCLickChange}
          >
            Select a Date
          </Button>
         {calenderOpen ? <AvenirMiniCalendar h="100%" w="max-content" selectRange={false} /> : null}
        </Box>
        <Button
          colorScheme="purple"
          bg="avenirSuitabilityColor.600"
          variant="solid"
          p="12px"
          borderRadius="12px"
          h="36px"
          w="36px"
          _hover={{
            bg: 'avenirSuitabilityColor.600',
          }}
        >
          {<AiOutlineCheck style={{ fontSize: '24px' }} />}
        </Button>
      </Flex>
    </Box>
  );
}
