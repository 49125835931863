import { SET_SUITABILITY_PROGRESS } from "./actions";

const initialState = {
    "SectionNumber": 0,
    "SubsectionNumber": 0,
    "QuestionsInsideSubsection": 0,
    "CurrentQuestionNumber": 0,
    "ReportFinished": false
  };

const SuitabilityProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUITABILITY_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default SuitabilityProgressReducer;