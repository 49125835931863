import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Text,
  Flex,
  Step,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Button,
} from '@chakra-ui/react';
import { AvenirLogo } from 'components/icons/Icons';
import { AiOutlineLoading3Quarters, AiOutlineDownload } from 'react-icons/ai';
import { CiLock } from 'react-icons/ci';
export default function Default({
  sectionDetails,
  sectionChange,
  currentSection,
  subSectionNumber,
  isReportFinished,
  generatePdfOrDoc
}) {
  // Define your sections and subsections
  const financialData = useSelector((state) => state);
  const SuitabilityProgress = financialData.spr;
  const suitabilityReportSections = sectionDetails;
  const sections = [
    {
      title: 'Upload clients’ information',
      subsections: [
        {
          sectionName: 'Upload Information',
          sectionNumber: 1,
          sectionText: 'Upload Information',
        },
      ],
    },
    {
      title: 'Fill Each Section',
      subsections: suitabilityReportSections,
    },
    {
      title: 'Review Executive Summary',
      subsections: [
        {
          sectionName: 'Validate Summary',
          sectionNumber: 1,
          sectionText: 'Validate Summary',
        },
      ],
    },
    {
      title: 'Generate your report',
      subsections: [
        {
          sectionName: 'Validate Report',
          sectionNumber: 1,
          sectionText: 'Validate Report',
        },
      ],
    },
  ];
  // Initialize an array to track the active step for each section
  const [activeSteps, setActiveSteps] = useState(sections.map(() => -1));
  // Function to update the active step for a specific section
  // const updateActiveStep = (sectionIndex, stepIndex) => {
  //   const newActiveSteps = [...activeSteps];
  //   newActiveSteps[sectionIndex] = stepIndex;
  //   setActiveSteps(newActiveSteps);
  // };
  const clickedGeneratePdfOrDOc = (value)=>{
    if(isReportFinished){
      generatePdfOrDoc(value)
    }
  }
  useEffect(() => {
    // updateActiveStep(0, SuitabilityProgress.SectionNumber - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SuitabilityProgress.SectionNumber]);
  useEffect(() => {
    const newActiveSteps = [...activeSteps]; // Start with a copy of the current activeSteps
    if (currentSection === 0) {
      newActiveSteps[0] = 0;
    } else {
      newActiveSteps[0] = 1;
      newActiveSteps[currentSection] = subSectionNumber-1;
      if(isReportFinished  && newActiveSteps[1] === 9998){
        newActiveSteps[2] = 1;
        newActiveSteps[3] = 1;
        setActiveSteps(newActiveSteps);
        sectionChange(4)
      }
    }
    setActiveSteps(newActiveSteps);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection, subSectionNumber]);
  return (
    <Box>
      <Flex
        width="100%"
        justifyContent="center"
        borderBottom="1px"
        borderColor="avenirGrey.200"
        borderStyle="solid"
      >
        <AvenirLogo h="46px" w="177px" mb="18px" />
      </Flex>
      <Box mt="8px">
        <Box h="70vh" overflow="scroll">
          {sections.map((section, index) => (
            <Box key={index}>
              <Text
                sx={{ textStyle: 'manropeHeading' }}
                fontWeight="700"
                m="16px 0 16px 0"
              >
                {index + 1}. {section.title}
              </Text>
              <Box>
                <Stepper
                  index={activeSteps[index]}
                  orientation="vertical"
                  h={
                    index === 1 ? `${section.subsections.length * 65}px` : '35px'
                  }
                  gap="0"
                >
                  {section.subsections.map((step, subindex) => (
                    <Step key={subindex}>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<CiLock color="#E2E8F0"/>}
                          active={<AiOutlineLoading3Quarters color="#4E13C3" />}
                        />
                      </StepIndicator>
                      <Box flexShrink="0">
                        <StepTitle
                          color={
                            subindex <= activeSteps[index]
                              ? 'avenirSuitabilityColor.800'
                              : '#E2E8F0'
                          }
                          sx={{ textStyle: 'manropeHeading' }}
                          fontWeight="700"
                        >
                          {step.sectionName}
                        </StepTitle>
                      </Box>
                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>
          ))}
        </Box>
        <Flex gap="16px" mt="30px">
          <Button
            leftIcon={<AiOutlineDownload style={{ fontSize: '24px' }} />}
            colorScheme="avenirSuitabilityColor"
            bg={isReportFinished && activeSteps[1] === 9998 ? 'avenirSuitabilityColor.800' : 'avenirPurple.200'}
            variant="solid"
            p="12px 18px"
            borderRadius="10px"
            h="46px"
            _hover={{
              bg: isReportFinished && activeSteps[1] === 9998 ? 'avenirSuitabilityColor.800' : 'avenirPurple.200',
            }}
            onClick={() => clickedGeneratePdfOrDOc('PDF')}
          >
            PDF
          </Button>
          <Button
            leftIcon={<AiOutlineDownload style={{ fontSize: '24px' }} />}
            colorScheme="avenirSuitabilityColor"
            bg={isReportFinished && activeSteps[1] === 9998 ? 'avenirSuitabilityColor.800' : 'avenirPurple.200'}
            variant="solid"
            p="12px 18px"
            borderRadius="10px"
            h="46px"
            _hover={{
              bg: isReportFinished && activeSteps[1] === 9998 ? 'avenirSuitabilityColor.800' : 'avenirPurple.200',
            }}
            onClick={() => clickedGeneratePdfOrDOc('DOC')}
          >
            DOC
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
