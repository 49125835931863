import React, { useState, useEffect } from 'react';
import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

const DotSelector = ({ value,topicColor, onChange }) => {
  const handleClick = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <HStack spacing={2}>
      {[...Array(5)].map((_, index) => (
        <Icon
          as={FaCircle}
          key={index}
          cursor="pointer"
          color={index < value ? topicColor : 'gray.300'}
          onClick={() => handleClick(index + 1)}
        />
      ))}
    </HStack>
  );
};

const EmphasizeTopics = ({ valueProps, valueQuestion }) => {
  const [topics, setTopics] = useState([]);
  const [values, setValues] = useState(Array(8).fill(0));
  const [countValues, setcountValues] = useState(0);

  const handleValueChange = (index) => (newValue) => {
    const newValues = [...values];
    let num = Math.abs(newValue - newValues[index])
    let countedValue = (num + countValues) <= 16 ? newValue : (16-countValues)
    newValues[index] = countedValue;
    setValues(newValues);
  };
  useEffect(() =>{
    let counter =  0
    values.forEach((val) =>{
      counter +=val
    }) 
    setcountValues(counter)
  },[values])
  useEffect(() => {
    let newTopics = [];
    newTopics = newTopics.concat(valueProps.vMapQuestionsContext[9].answers);
    newTopics = newTopics.concat(valueProps.vMapQuestionsContext[10].answers);
    newTopics = newTopics.concat(valueProps.vMapQuestionsContext[11].answers);
    setTopics(newTopics);
  }, [valueProps]);
  const colorPicker = (topic) => {
   if(valueProps.vMapQuestionsContext[9].answers.includes(topic)){
    return "#E7D6FF";
   } else if (valueProps.vMapQuestionsContext[10].answers.includes(topic)){
    return "#FFF0D4";
   } else {
    return "#FFE3D4";
   }
  }
  return (
    <VStack spacing={4} align="stretch" p={5}  width={{ base: '95%', xl: '60%' }}>
      <Text sx={{
          textStyle: { base: 'popinsmallHeading', xl: 'popinlarge' },
        }} fontWeight="bold">
        {valueQuestion.question}
      </Text>
      <Flex justifyContent="space-between">
       <Text sx={{textStyle: 'popinsmall'}}>You can use 16 points in total</Text>
       <Text sx={{textStyle: 'popinsmallHeading'}}>Used {countValues}/16</Text>
      </Flex>
      <Flex flexDirection="column" rowGap="16px" h="60vh" overflow="scroll">
        {topics.map((topic, index) => (
          <Flex
            key={topic}
            p={5}
            borderWidth="1px"
            borderRadius="24px"
            padding={{ base: "15px 20px", xl: "20px 25px" }}
            justifyContent="space-between"
            alignItems="center"
            borderColor= {colorPicker(topic)}
            bgGradient={`linear(to-r, ${colorPicker(topic)} 20px, white 20px)`}
          >
            <Text sx={{textStyle: 'medium'}}>{topic}</Text>
            <DotSelector
              value={values[index]}
              topicColor = {colorPicker(topic)}
              onChange={handleValueChange(index)}
            />
          </Flex>
        ))}
      </Flex>
    </VStack>
  );
};

export default EmphasizeTopics;
